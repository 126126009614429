import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { Images } from "../../helpers/Images";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PrimaryButton from "../../Components/atoms/Primary/PrimaryButton";
import Groups2Icon from "@mui/icons-material/Groups2";
import DescriptionIcon from "@mui/icons-material/Description";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import DevicesIcon from "@mui/icons-material/Devices";
import EventIcon from "@mui/icons-material/Event";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import InsightsIcon from "@mui/icons-material/Insights";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ServiceCard from "../../Components/molecules/Cards/ServiceCard";
import ServiceSection from "../../Components/molecules/Cards/ServiceSection";
import SchoolIcon from "@mui/icons-material/School";
import TodayIcon from "@mui/icons-material/Today";
import CampaignIcon from "@mui/icons-material/Campaign";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";

const Services = [
  {
    title: "STEM School Transformation",
    desc: "Converting educational institutions to work with the international STEM system through training and qualification programs for teachers to teach with the STEM educational system and preparing appropriate study materials for this matter.",
    icon: <SchoolIcon style={{ color: "#002369", fontSize: "120px" }} />,
    right: true,
  },
  {
    title: "STEM School Day",
    desc: "Providing STEM curricula during the school day. Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime reprehenderit sed obcaecati magni saepe molestias tempora recusandae aperiam veniam maiores. Ex omnis quidem possimus officiis enim, molestias facilis repellendus hic.",
    icon: <TodayIcon style={{ color: "#002369", fontSize: "120px" }} />,
    right: false,
  },
  {
    title: "STEM Clubs",
    desc: "Providing integrated educational programs with the STEM educational system after the school day or holidays, with the aim of introducing STEM education in the company’s main programs. With the aim of qualifying for international competitions or providing educational programs for students with the STEM system.",
    icon: <Groups2Icon style={{ color: "#002369", fontSize: "120px" }} />,
    right: true,
  },
  {
    title: "Stem camps",
    desc: "Providing educational camps that include many of the company’s main training programs in a specific period of time, with the aim of gaining more experience for students and integrating them into more STEM activities in a short time to learn about them. Education in all of our previous programs is a combination of entertainment and education to promote interaction and enjoyment of learning outside of formal school hours.",
    icon: <CampaignIcon style={{ color: "#002369", fontSize: "120px" }} />,
    right: false,
  },
  {
    title: "Training and Consulting",
    desc: "Providing training programs and specialized technical consultations for teachers in the field of educational stem in general and the field of robotics and programming competitions in particular.",
    icon: (
      <CardMembershipIcon style={{ color: "#002369", fontSize: "120px" }} />
    ),
    right: true,
  },
  {
    title: "STEM International Competitions",
    desc: "Skill Code holds 6 international competitions aiming to provide STEM education in different systems, for which we act as the main supporter for all participating educational institutions.",
    icon: <EmojiEventsIcon style={{ color: "#002369", fontSize: "120px" }} />,
    right: false,
  },
  {
    title: "Accreditation",
    desc: "Provide teacher, curriculum and school accreditation through Stem.ORG and Steminds.ORG accreditation.",
    icon: (
      <WorkspacePremiumIcon style={{ color: "#002369", fontSize: "120px" }} />
    ),
    right: true,
  },
];

const ServicesTemplate = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: {
            xs: "30px 20px",
            sm: "30px 60px",
            lg: "30px 120px",
            xl: "50px 120px",
          },
          background: `linear-gradient(80deg, #1a3978dd,#007effcc,#007effcc, #1a3978dd), #000000cc`,
          // background: `url(${Images.HomeBack})`,
          // backgroundSize: "cover",
          // backgroundPosition: "center",
          // backgroundRepeat: "no-repeat",
          minHeight: "380px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          boxShadow: "0px 0px 30px 0px rgba(0,0,0,0.15) inset",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "40px", sm: "45px" },
            fontWeight: "500",
            color: "primary.light",
            marginBottom: "20px",
          }}
        >
          Skill Code Services
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "center",
            fontWeight: "500",
            fontSize: { xs: "15px", sm: "20px" },
            color: "primary.light",
          }}
        >
          Skill Code is committed to creating a positive impact on education,
          inspiring a new era of learners who are not only equipped with
          technical skills but also possess the creativity and critical thinking
          necessary for success in the 21st century. Together, we are building a
          brighter future through the power of STEAM education.
        </Typography>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <PrimaryButton
            classBtn={"center"}
            text={"Join Skill Code"}
            style={{
              textTransform: "capitalize",
              borderRadius: "20px",
              color: "var(--secondary)",
              backgroundColor: "white",
              border: "2px solid var(--secondary)",
              height: "40px",
              padding: "0 25px",
              width: "fit-content",
              margin: "25px",
            }}
            click={() => navigate(`/contact`)}
          />
        </div>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1200px",
          margin: "auto",
        }}
      >
        {Services?.map((ser) => (
          <ServiceSection
            icon={ser?.icon}
            title={ser?.title}
            desc={ser?.desc}
            right={ser?.right}
          />
        ))}
      </Box>
      {/* <Box
        id={"questions"}
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "40px" },
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          Questions & Answers
        </Typography>
        <Box
          sx={{
            height: "3px",
            maxWidth: { xs: "120px", md: "200px" },
            width: "100%",
            backgroundColor: "primary.main",
            margin: "10px auto 0 auto",
          }}
        />
        <Box id={"questions"} sx={{ marginTop: "50px" }}>
          {[
            {
              question: "How to join ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
            },
            {
              question: "What is the max number of students in a single team ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
            },
            {
              question: "What is the max number of students in a single team ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
            },
            {
              question: "What is the max number of students in a single team ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
            },
          ].map((item) => (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{item.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{item.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box> */}
    </>
  );
};

export default ServicesTemplate;
