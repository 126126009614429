import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Images } from "../../../helpers/Images";
import "./Footer.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import YouTubeIcon from "@mui/icons-material/YouTube";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#050b21",
      }}
    >
      <Box
        sx={{
          width: "100%",
          padding: { xs: "20px 20px", sm: "20px 60px", lg: "20px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: { xs: "center", md: "space-between" },
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "auto" },
              textAlign: { xs: "center", md: "start" },
            }}
          >
            <img
              style={{ height: "100px", cursor: "pointer" }}
              src={Images.WhiteLogo}
              alt="Skill Code Logo"
              onClick={() => navigate("/")}
            />
            <Typography
              variant={"p"}
              sx={{
                textAlign: { xs: "center", md: "start" },
                fontWeight: "200",
                color: "primary.light",
                fontSize: "12px",
                margin: { xs: "10px auto", md: "10px 0" },
                maxWidth: { xs: "100%", md: "200px" },
                display: "block",
                lineHeight: "1.6",
              }}
            >
              Skill Code, as a premier provider of business-to-business STEAM
              education (Science, Technology, Engineering, Arts, and
              Mathematics) solutions
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "row" },
                justifyContent: { xs: "center", md: "flex-start" },
              }}
            >
              <a
                href="https://www.facebook.com/profile.php?id=61554766666865"
                target="_blank"
                aria-label="Facebook"
              >
                <FacebookIcon className="SocialFooterIcon" />
              </a>
              <a
                href="https://www.linkedin.com/company/skill-code-steam/"
                target="_blank"
                aria-label="Linkedin"
              >
                <LinkedInIcon className="SocialFooterIcon" />
              </a>
              <a
                href="https://www.instagram.com/skil.lcode/"
                target="_blank"
                aria-label="Instagram"
              >
                <InstagramIcon className="SocialFooterIcon" />
              </a>
              <a
                href="https://x.com/SkillcodeKSA"
                target="_blank"
                aria-label="X"
              >
                <XIcon className="SocialFooterIcon" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCHJwJUH1KMpNqvB6pHq1EbQ"
                target="_blank"
                aria-label="Youtube"
              >
                <YouTubeIcon className="SocialFooterIcon" />
              </a>
            </Box>
          </Box>
          {/* <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              flexDirection: "column",
              alignItems: "flex-start",
              paddingTop: "20px",
            }}
          >
            <Typography
              variant={"h6"}
              sx={{
                textAlign: "center",
                fontWeight: "200",
                color: "primary.light",
                fontSize: "15px",
                marginRight: "5px",
              }}
            >
              Quick links
            </Typography>
            <ul className="footerLinks">
              <li onClick={() => navigate("/")}>Home</li>
              <li onClick={() => navigate("/services")}>Our services</li>
              <li onClick={() => navigate("/contact")}>Free consultation</li>
              <li onClick={() => navigate("/parent")}>Parental platform</li>
              <li onClick={() => navigate("/about")}>About Skill Code</li>
            </ul>
          </Box> */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              alignItems: "flex-start",
              paddingTop: { xs: "15px" },
            }}
            className="desktop"
          >
            <Typography
              variant={"h6"}
              sx={{
                textAlign: "center",
                fontWeight: "200",
                color: "primary.light",
                fontSize: "15px",
                marginRight: "5px",
              }}
            >
              Egypt Branch
            </Typography>
            <div className="footerListIcon">
              <div>
                <LocationOnIcon />
              </div>
              127 Mostafa El Nahaas St. Nasr City - Cairo - Egypt
            </div>
            <div className="footerListIcon">
              <div>
                <LocalPhoneIcon />
              </div>
              +201005620076
            </div>
            <div className="footerListIcon">
              <div>
                <EmailIcon />
              </div>
              info@skill-code.com
            </div>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              alignItems: "flex-start",
              paddingTop: { xs: "15px" },
            }}
            className="desktop"
          >
            <Typography
              variant={"h6"}
              sx={{
                textAlign: "center",
                fontWeight: "200",
                color: "primary.light",
                fontSize: "15px",
                marginRight: "5px",
              }}
            >
              Saudi Arabia Branch
            </Typography>
            <div className="footerListIcon">
              <div>
                <LocationOnIcon />
              </div>
              Jeddah/Riyadh - Saudi Arabia
            </div>
            <div className="footerListIcon">
              <div>
                <LocalPhoneIcon />
              </div>
              +966558634338
            </div>
            <div className="footerListIcon">
              <div>
                <EmailIcon />
              </div>
              ksa@skill-code.com
            </div>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              alignItems: "flex-start",
              paddingTop: { xs: "15px" },
            }}
            className="desktop"
          >
            <Typography
              variant={"h6"}
              sx={{
                textAlign: "center",
                fontWeight: "200",
                color: "primary.light",
                fontSize: "15px",
                marginRight: "5px",
              }}
            >
              Kuwait Branch
            </Typography>
            <div className="footerListIcon">
              <div>
                <LocationOnIcon />
              </div>
              Kuwait
            </div>
            <div className="footerListIcon">
              <div>
                <LocalPhoneIcon />
              </div>
              +96560021270
            </div>
            <div className="footerListIcon">
              <div>
                <EmailIcon />
              </div>
              Kuwait@skill-code.com
            </div>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            borderTop: "1px solid #fff",
            marginTop: "20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: "5px",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Typography
            variant={"h6"}
            sx={{
              textAlign: "center",
              fontWeight: "200",
              color: "primary.light",
              fontSize: "15px",
              marginTop: { xs: "5px", sm: "0" },
            }}
          >
            SkillCode© 2024 All Rights reserved.
          </Typography>
          <a href="https://pulsonic.io/" target="_blank">
            <Typography
              variant={"h6"}
              sx={{
                textAlign: "center",
                fontWeight: "200",
                color: "primary.light",
                fontSize: "15px",
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
                cursor: "pointer",
              }}
            >
              Powered by{" "}
              <img
                src={Images.pulsonic}
                alt="pulsonic"
                style={{ height: "20px", marginLeft: "10px" }}
              />
            </Typography>
          </a>
        </Box>
        {/*<Box
          sx={{
            borderTop: "1px solid #fff",
            marginTop: "20px",
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: { xs: "center", sm: "center" },
          }}
        >
           <Typography
            variant={"h6"}
            sx={{
              textAlign: "center",
              fontWeight: "200",
              color: "primary.light",
              fontSize: "15px",
              marginTop: { xs: "5px", sm: "0" },
            }}
          >
            SkillCode© 2024 All Rights reserved.
          </Typography> */}
        {/* <Typography
            variant={"h6"}
            sx={{
              textAlign: "center",
              fontWeight: "200",
              color: "primary.light",
              fontSize: "15px",
            }}
          >
            Powered by PULSONIC©
          </Typography> 
        </Box>*/}
      </Box>
    </Box>
  );
};

export default Footer;

// RobotChallenge is one of the biggest competitions for self-made, autonomous and mobile robots worldw
