import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";

const ServiceCard = ({ desc, icon, title }) => {
  return (
    <>
      <Box
        sx={{
          height: "100%",
          width: { xs: "95%", md: "90%" },
          backgroundColor: "primary.light",
          margin: "10px",
          borderRadius: "15px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "20px 10px",
        }}
      >
        <Box
          sx={{
            height: "90px",
            width: "90px",
            backgroundColor: "secondary.main",
            margin: "0 10px 10px",
            borderRadius: "50%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {icon}
        </Box>
        <h4
          style={{
            color: "var(--primary)",
            maxWidth: "300px",
            textAlign: "center",
          }}
        >
          {title}
        </h4>
        <p
          style={{
            color: "var(--primary)",
            maxWidth: "300px",
            textAlign: "center",
            fontSize: "14px",
            marginTop: "5px",
          }}
        >
          {desc}
        </p>
      </Box>
    </>
  );
};

export default ServiceCard;
