import { Box, Button, Typography } from "@mui/material";
import React from "react";
import "./Welcome.css";
import { Images } from "../../../../helpers/Images";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../atoms/Primary/PrimaryButton";
import Number from "../Numbers/Number";
import { useTranslation } from "react-i18next";

const Welcome = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "100%",
        padding: {
          xs: "20px 20px",
          sm: "30px 60px",
          lg: "30px 120px",
          xl: "50px 120px",
        },
        // background: `url(${Images.HomeBack})`,
        // backgroundSize: "cover",
        // backgroundPosition: "center",
        // backgroundRepeat: "no-repeat",
        background: `linear-gradient(80deg, #1a3978dd,#007effcc,#007effcc, #1a3978dd), #000000cc`,
        minHeight: "380px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        boxShadow: "0px 0px 30px 0px rgba(0,0,0,0.15) inset",
      }}
    >
      <Typography
        variant={"h1"}
        sx={{
          textAlign: "center",
          fontSize: { xs: "40px", sm: "50px" },
          fontWeight: "500",
          color: "primary.light",
        }}
      >
        {t("Home.Welcome.title")}
      </Typography>
      <Typography
        variant={"h2"}
        sx={{
          textAlign: "center",
          fontWeight: "500",
          fontSize: { xs: "15px", sm: "24px" },
          color: "primary.light",
          maxWidth: "800px",
          margin: "5px auto",
        }}
      >
        Together, we are building a brighter future through the power of STEAM
        education towards the valubale learning
      </Typography>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <PrimaryButton
          classBtn={"center"}
          text={"Get Your Free Consultation Now"}
          style={{
            textTransform: "capitalize",
            borderRadius: "20px",
            color: "var(--secondary)",
            backgroundColor: "white",
            border: "2px solid var(--secondary)",
            height: "40px",
            padding: "0 25px",
            width: "fit-content",
            marginTop: "20px",
          }}
          click={() => navigate(`/contact`)}
        />
      </div>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
          marginTop: "20px",
        }}
      ></Box>
    </Box>
  );
};

export default Welcome;
