import React from "react";
import Welcome from "../../Components/organisms/Home/Welcome/Welcome";
import STREAM from "../../Components/organisms/Home/STREAM/STREAM";
import CarouselHome from "../../Components/organisms/Home/CarouselHome/CarouselHome";
import ServicesHome from "../../Components/organisms/Home/ServicesHome/ServicesHome";
import JoinUsHome from "../../Components/organisms/Home/JoinUsHome/JoinUsHome";
import Carousel from "react-material-ui-carousel";
import { Box } from "@mui/material";
import { Images } from "../../helpers/Images";
import PrimaryButton from "../../Components/atoms/Primary/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HomeTemplate = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  var items = [
    {
      name: "Explore Our Corporate Programs",
      description: "See More",
      image: Images.kids2,
      link: "/services",
    },
    {
      name: "Find Us On Facebook",
      description: "See More",
      image: Images.kids3,
      link: "",
    },
    {
      name: "Explore Our STEAM Events",
      description: "See More",
      image: Images.kids4,
      link: "/services",
    },
    {
      name: "Explore Our STEAM-Based Curricula",
      description: "See More",
      image: Images.kids5,
      link: "/services",
    },
  ];
  return (
    <>
      <Welcome />
      <STREAM />
      <ServicesHome />
      <Box
        sx={{
          width: "100%",
          padding: {
            xs: "30px 20px 20px",
            sm: "30px 60px 20px",
            lg: "40px 120px 40px",
          },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          maxWidth: "1500px",
          margin: "0 auto 10px",
        }}
      >
        <Carousel
          indicators={false}
          navButtonsAlwaysVisible={true}
          animation="slide"
          navButtonsProps={{
            className: "buttonsCarousel",
          }}
        >
          {items.map((item, i) => (
            <div
              key={i}
              style={{
                background: `linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0, 0.4)), url(${item?.image})`,
                minHeight: "350px",
                backgroundPosition: "center",
              }}
              className="carouselBox"
            >
              <h2>{item.name}</h2>
              <PrimaryButton
                classBtn={"center"}
                text={item.description}
                style={{
                  textTransform: "capitalize",
                  borderRadius: "20px",
                  color: "white",
                  backgroundColor: "",
                  border: "2px solid white",
                  height: "40px",
                  padding: "0 40px",
                  width: "fit-content",
                  marginTop: "20px",
                }}
                click={() => navigate(item.link)}
              />
            </div>
          ))}
        </Carousel>
      </Box>
      <CarouselHome
        title={"Our Clients"}
        clients={true}
        imgs={[
          Images.schoolLogo1,
          Images.schoolLogo2,
          Images.schoolLogo3,
          Images.schoolLogo4,
          Images.schoolLogo5,
          Images.schoolLogo6,
          Images.schoolLogo7,
          Images.schoolLogo8,
          Images.schoolLogo9,
        ]}
      />
      {/* <JoinUsHome /> */}
      <CarouselHome
        title={"Our Partners"}
        imgs={[
          Images.schoolLogo1,
          Images.schoolLogo2,
          Images.schoolLogo3,
          Images.schoolLogo4,
          Images.schoolLogo5,
          Images.schoolLogo6,
          Images.schoolLogo7,
          Images.schoolLogo8,
          Images.schoolLogo9,
        ]}
      />
    </>
  );
};

export default HomeTemplate;
