import React, { useState } from "react";
import "./Number.css";
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";
import { Images } from "../../../../helpers/Images";
import { Grid, Typography } from "@mui/material";
import { Box, width } from "@mui/system";

function Number() {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        maxWidth: "1500px",
        margin: "auto",
      }}
    >
      <Typography
        variant={"h1"}
        sx={{
          textAlign: "center",
          fontSize: { xs: "30px", sm: "38px" },
          fontWeight: "500",
          color: "primary.main",
          margin: "0 auto 20px",
        }}
      >
         Our Achievements 
      </Typography>
      <Box
        sx={{
          height: "3px",
          width: "150px",
          backgroundColor: "primary.light",
          margin: "10px auto",
        }}
      />
      <div className="counter">
        <ScrollTrigger
          onEnter={() => setCounterOn(true)}
          onExit={() => setCounterOn(false)}
          style={{ width: "100%" }}
        >
          <Grid container justifyContent={"center"}>
            {[
              {
                number: "25",
                title: "School Labs",
              },
              { number: "350", title: "STEM Trainers" },
              {
                number: "7",
                title: "STEM Transformed Schools",
              },
            ].map((item, i) => (
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={4}
                key={i}
                justifyContent={"center"}
              >
                <div className="CounterItem">
                  <div className="outer">
                    <div className="inner">
                      <div id="number">
                        {(counterOn && (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              version="1.1"
                              width="150px"
                              height="150px"
                              className="circles"
                            >
                              <defs>
                                <linearGradient id="GradientColor">
                                  <stop offset="0%" stop-color="#96d228" />
                                  <stop offset="100%" stop-color="#96d228" />
                                </linearGradient>
                              </defs>
                              <circle
                                style={{
                                  animationName: counterOn ? "counter" : "",
                                }}
                                cx="75"
                                cy="75"
                                r="67"
                                stroke-linecap="round"
                              />
                            </svg>
                            <CountUp
                              start={0}
                              end={item.number}
                              duration={3}
                              delay={1}
                              style={{ color: "var(--primary" }}
                            />
                            {item?.title === "Teams" ? "+" : ""}
                          </>
                        )) ||
                          0}
                      </div>
                    </div>
                  </div>
                  <p className="counterText">{item.title}</p>
                </div>
              </Grid>
            ))}
          </Grid>
        </ScrollTrigger>
      </div>
    </Box>
  );
}

export default Number;
