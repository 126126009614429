import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import "./ServicesHome.css";
import { Images } from "../../../../helpers/Images";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../atoms/Primary/PrimaryButton";
import Groups2Icon from "@mui/icons-material/Groups2";
import DescriptionIcon from "@mui/icons-material/Description";
import ServiceCard from "../../../molecules/Cards/ServiceCard";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import DevicesIcon from "@mui/icons-material/Devices";
import EventIcon from "@mui/icons-material/Event";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import InsightsIcon from "@mui/icons-material/Insights";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import SchoolIcon from "@mui/icons-material/School";
import TodayIcon from "@mui/icons-material/Today";
import CampaignIcon from "@mui/icons-material/Campaign";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";

const Services = [
  {
    title: "STEM School Transformation",
    icon: <SchoolIcon style={{ color: "white", fontSize: "50px" }} />,
    desc: "Converting educational institutions to work with the international STEM system.",
  },
  {
    title: "STEM School Day",
    icon: <TodayIcon style={{ color: "white", fontSize: "50px" }} />,
    desc: "Providing STEM curricula during the school day.",
  },
  {
    title: "STEM Clubs",
    icon: <Groups2Icon style={{ color: "white", fontSize: "50px" }} />,
    desc: "Providing integrated educational programs with the STEM educational system after the school day or holidays.",
  },
  {
    title: "Stem Camps",
    icon: <CampaignIcon style={{ color: "white", fontSize: "50px" }} />,
    desc: "Providing educational camps that include many of the company’s main training programs in a specific period of time.",
  },
  {
    title: "Training and Consulting",
    icon: <CardMembershipIcon style={{ color: "white", fontSize: "50px" }} />,
    desc: "Providing training programs and specialized technical consultations for teachers in the field of educational stem.",
  },
  {
    title: "STEM International Competitions",
    icon: <EmojiEventsIcon style={{ color: "white", fontSize: "50px" }} />,
    desc: "Skill Code holds 6 international competitions aiming to provide STEM education in different systems.",
  },
  {
    title: "Accreditation",
    icon: <WorkspacePremiumIcon style={{ color: "white", fontSize: "50px" }} />,
    desc: "Provide teacher, curriculum and school accreditation through Stem.ORG and Steminds.ORG accreditation.",
  },
];

const ServicesHome = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "100%",
        background: `linear-gradient(80deg, #1a3978dd,#007effcc,#007effcc, #1a3978dd), #000000cc`,
        // background: `url(${Images.HomeBack})`,
        // backgroundSize: "cover",
        // backgroundPosition: "center",
        // backgroundRepeat: "no-repeat",
      }}
    >
      <Box
        sx={{
          width: "100%",
          padding: {
            xs: "50px 20px",
            sm: "50px 60px",
            lg: "70px 120px",
          },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "35px", sm: "45px" },
            fontWeight: "500",
            color: "primary.light",
          }}
        >
          Our Services
        </Typography>
        {/* <Box
          sx={{
            height: "3px",
            width: "200px",
            backgroundColor: "primary.light",
            margin: "10px auto",
          }}
        /> */}

        <Grid
          container
          style={{
            justifyContent: "center",
            marginTop: "20px",
            maxWidth: "1500px",
            margin: "5px auto",
          }}
        >
          {Services?.map((ser, i) => (
            <Grid
              key={i}
              item
              xs={6}
              sm={6}
              md={4}
              lg={3}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "15px",
              }}
            >
              <ServiceCard
                icon={ser?.icon}
                desc={ser?.desc}
                title={ser?.title}
              />
            </Grid>
          ))}
        </Grid>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <PrimaryButton
            classBtn={"center"}
            text={"See more"}
            style={{
              textTransform: "capitalize",
              borderRadius: "20px",
              color: "white",
              backgroundColor: "",
              border: "2px solid white",
              height: "40px",
              padding: "0 40px",
              width: "fit-content",
              marginTop: "10px",
            }}
            click={() => navigate(`/services`)}
          />
        </div>
      </Box>
    </Box>
  );
};

export default ServicesHome;
