import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import HomePage from "./Pages/Home/Home.page";
import AboutPage from "./Pages/About/About.page";
import ContactPage from "./Pages/Contact/Contact.page";
import ServicesPage from "./Pages/Services/Services.page";
import ParentPage from "./Pages/Parent/Parent.page";
import SteamPage from "./Pages/Steam/Steam.page";
import NavBar from "./Components/organisms/NavBar/NavBar";
import Footer from "./Components/organisms/Footer/Footer";
import { Box } from "@mui/system";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import englishFile from "./helpers/translationEG.json";
import arabicFile from "./helpers/translationAR.json";
import "react-toastify/dist/ReactToastify.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

i18n.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "en", // default language
  resources: {
    en: englishFile,
    ar: arabicFile,
  },
});

const theme = createTheme({
  palette: {
    primary: {
      light: "#fff",
      main: "#002369",
      dark: "#000",
      darker: "#414141",
    },
    secondary: {
      light: "#23aef4",
      main: "#96d228",
      dark: "#a4a4a3",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <NavBar />
          <Box sx={{ width: "100%", flex: "1", marginTop: "80px" }}>
            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route path="/services" element={<ServicesPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/parent" element={<ParentPage />} />
              <Route path="/steam/:id" element={<SteamPage />} />
              <Route path="/steam" element={<SteamPage />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Box>
          <ToastContainer />
          <Footer />
        </BrowserRouter>
      </I18nextProvider>
    </ThemeProvider>
  );
}

export default App;
